import { default as explore_45courses3T2XhVpsZoMeta } from "/vercel/path0/web/pages/about/explore-courses.vue?macro=true";
import { default as index5WqLcFBFtGMeta } from "/vercel/path0/web/pages/about/index.vue?macro=true";
import { default as indexvGMLrCzrS1Meta } from "/vercel/path0/web/pages/account/index.vue?macro=true";
import { default as reset_45passwordf9VzubbknJMeta } from "/vercel/path0/web/pages/account/reset-password.vue?macro=true";
import { default as course_45requestsP9y2ESxpaBMeta } from "/vercel/path0/web/pages/admin/course-requests.vue?macro=true";
import { default as _91areaSlug_935RHbCZS09xMeta } from "/vercel/path0/web/pages/areas/[areaSlug].vue?macro=true";
import { default as indexUsJ9Sal3cTMeta } from "/vercel/path0/web/pages/areas/index.vue?macro=true";
import { default as _91slug_937Tz4qJICVzMeta } from "/vercel/path0/web/pages/articles/[slug].vue?macro=true";
import { default as editBpCDA5NnDkMeta } from "/vercel/path0/web/pages/articles/edit.vue?macro=true";
import { default as indexQtIGAZ16HgMeta } from "/vercel/path0/web/pages/articles/index.vue?macro=true";
import { default as newxWYZiLcisMMeta } from "/vercel/path0/web/pages/articles/new.vue?macro=true";
import { default as authenticate_45app6Veyl9qewbMeta } from "/vercel/path0/web/pages/authenticate-app.vue?macro=true";
import { default as editDqAsoZulI8Meta } from "/vercel/path0/web/pages/courses/[courseId]/edit.vue?macro=true";
import { default as indexos0vIsGzhbMeta } from "/vercel/path0/web/pages/courses/[courseId]/index.vue?macro=true";
import { default as LoggedInIndexJYMIzElVFVMeta } from "/vercel/path0/web/pages/courses/[courseId]/LoggedInIndex.vue?macro=true";
import { default as LoggedOutIndex2lHB6vVosjMeta } from "/vercel/path0/web/pages/courses/[courseId]/LoggedOutIndex.vue?macro=true";
import { default as previewPCevnetqdxMeta } from "/vercel/path0/web/pages/courses/[courseId]/preview.vue?macro=true";
import { default as runDT6Ya0SZzZMeta } from "/vercel/path0/web/pages/courses/[courseId]/run.vue?macro=true";
import { default as create0OIsbOkZu5Meta } from "/vercel/path0/web/pages/courses/create.vue?macro=true";
import { default as debug_45mapsid7aRh3Ym5Meta } from "/vercel/path0/web/pages/debug-maps.vue?macro=true";
import { default as coursesdnOySbgHo9Meta } from "/vercel/path0/web/pages/explore/courses.vue?macro=true";
import { default as indexEnecjc5U7LMeta } from "/vercel/path0/web/pages/explore/index.vue?macro=true";
import { default as indexjqPwd0e7eHMeta } from "/vercel/path0/web/pages/index.vue?macro=true";
import { default as loginzz3zAXJZnbMeta } from "/vercel/path0/web/pages/login.vue?macro=true";
import { default as privacyTancXnnePpMeta } from "/vercel/path0/web/pages/privacy.vue?macro=true";
import { default as checkout7mNSwKKE0oMeta } from "/vercel/path0/web/pages/pro/checkout.vue?macro=true";
import { default as analyzeudDP1RE8keMeta } from "/vercel/path0/web/pages/runs/[runId]/analyze.vue?macro=true";
import { default as indexhdf1FNgDNfMeta } from "/vercel/path0/web/pages/runs/[runId]/index.vue?macro=true";
import { default as supportLG7pJ0waS8Meta } from "/vercel/path0/web/pages/support.vue?macro=true";
import { default as termsXy0y1ahYNqMeta } from "/vercel/path0/web/pages/terms.vue?macro=true";
import { default as coursesDpPbHusfM6Meta } from "/vercel/path0/web/pages/users/[userId]/courses.vue?macro=true";
import { default as indexAaeAPqJ4dsMeta } from "/vercel/path0/web/pages/users/[userId]/index.vue?macro=true";
export default [
  {
    name: explore_45courses3T2XhVpsZoMeta?.name ?? "about-explore-courses",
    path: explore_45courses3T2XhVpsZoMeta?.path ?? "/about/explore-courses",
    meta: explore_45courses3T2XhVpsZoMeta || {},
    alias: explore_45courses3T2XhVpsZoMeta?.alias || [],
    redirect: explore_45courses3T2XhVpsZoMeta?.redirect || undefined,
    component: () => import("/vercel/path0/web/pages/about/explore-courses.vue").then(m => m.default || m)
  },
  {
    name: index5WqLcFBFtGMeta?.name ?? "about",
    path: index5WqLcFBFtGMeta?.path ?? "/about",
    meta: index5WqLcFBFtGMeta || {},
    alias: index5WqLcFBFtGMeta?.alias || [],
    redirect: index5WqLcFBFtGMeta?.redirect || undefined,
    component: () => import("/vercel/path0/web/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: indexvGMLrCzrS1Meta?.name ?? "account",
    path: indexvGMLrCzrS1Meta?.path ?? "/account",
    meta: indexvGMLrCzrS1Meta || {},
    alias: indexvGMLrCzrS1Meta?.alias || [],
    redirect: indexvGMLrCzrS1Meta?.redirect || undefined,
    component: () => import("/vercel/path0/web/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordf9VzubbknJMeta?.name ?? "account-reset-password",
    path: reset_45passwordf9VzubbknJMeta?.path ?? "/account/reset-password",
    meta: reset_45passwordf9VzubbknJMeta || {},
    alias: reset_45passwordf9VzubbknJMeta?.alias || [],
    redirect: reset_45passwordf9VzubbknJMeta?.redirect || undefined,
    component: () => import("/vercel/path0/web/pages/account/reset-password.vue").then(m => m.default || m)
  },
  {
    name: course_45requestsP9y2ESxpaBMeta?.name ?? "admin-course-requests",
    path: course_45requestsP9y2ESxpaBMeta?.path ?? "/admin/course-requests",
    meta: course_45requestsP9y2ESxpaBMeta || {},
    alias: course_45requestsP9y2ESxpaBMeta?.alias || [],
    redirect: course_45requestsP9y2ESxpaBMeta?.redirect || undefined,
    component: () => import("/vercel/path0/web/pages/admin/course-requests.vue").then(m => m.default || m)
  },
  {
    name: _91areaSlug_935RHbCZS09xMeta?.name ?? "areas-areaSlug",
    path: _91areaSlug_935RHbCZS09xMeta?.path ?? "/areas/:areaSlug()",
    meta: _91areaSlug_935RHbCZS09xMeta || {},
    alias: _91areaSlug_935RHbCZS09xMeta?.alias || [],
    redirect: _91areaSlug_935RHbCZS09xMeta?.redirect || undefined,
    component: () => import("/vercel/path0/web/pages/areas/[areaSlug].vue").then(m => m.default || m)
  },
  {
    name: indexUsJ9Sal3cTMeta?.name ?? "areas",
    path: indexUsJ9Sal3cTMeta?.path ?? "/areas",
    meta: indexUsJ9Sal3cTMeta || {},
    alias: indexUsJ9Sal3cTMeta?.alias || [],
    redirect: indexUsJ9Sal3cTMeta?.redirect || undefined,
    component: () => import("/vercel/path0/web/pages/areas/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_937Tz4qJICVzMeta?.name ?? "articles-slug",
    path: _91slug_937Tz4qJICVzMeta?.path ?? "/articles/:slug()",
    meta: _91slug_937Tz4qJICVzMeta || {},
    alias: _91slug_937Tz4qJICVzMeta?.alias || [],
    redirect: _91slug_937Tz4qJICVzMeta?.redirect || undefined,
    component: () => import("/vercel/path0/web/pages/articles/[slug].vue").then(m => m.default || m)
  },
  {
    name: editBpCDA5NnDkMeta?.name ?? "articles-edit",
    path: editBpCDA5NnDkMeta?.path ?? "/articles/edit",
    meta: editBpCDA5NnDkMeta || {},
    alias: editBpCDA5NnDkMeta?.alias || [],
    redirect: editBpCDA5NnDkMeta?.redirect || undefined,
    component: () => import("/vercel/path0/web/pages/articles/edit.vue").then(m => m.default || m)
  },
  {
    name: indexQtIGAZ16HgMeta?.name ?? "articles",
    path: indexQtIGAZ16HgMeta?.path ?? "/articles",
    meta: indexQtIGAZ16HgMeta || {},
    alias: indexQtIGAZ16HgMeta?.alias || [],
    redirect: indexQtIGAZ16HgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/web/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: newxWYZiLcisMMeta?.name ?? "articles-new",
    path: newxWYZiLcisMMeta?.path ?? "/articles/new",
    meta: newxWYZiLcisMMeta || {},
    alias: newxWYZiLcisMMeta?.alias || [],
    redirect: newxWYZiLcisMMeta?.redirect || undefined,
    component: () => import("/vercel/path0/web/pages/articles/new.vue").then(m => m.default || m)
  },
  {
    name: authenticate_45app6Veyl9qewbMeta?.name ?? "authenticate-app",
    path: authenticate_45app6Veyl9qewbMeta?.path ?? "/authenticate-app",
    meta: authenticate_45app6Veyl9qewbMeta || {},
    alias: authenticate_45app6Veyl9qewbMeta?.alias || [],
    redirect: authenticate_45app6Veyl9qewbMeta?.redirect || undefined,
    component: () => import("/vercel/path0/web/pages/authenticate-app.vue").then(m => m.default || m)
  },
  {
    name: editDqAsoZulI8Meta?.name ?? "courses-courseId-edit",
    path: editDqAsoZulI8Meta?.path ?? "/courses/:courseId()/edit",
    meta: editDqAsoZulI8Meta || {},
    alias: editDqAsoZulI8Meta?.alias || [],
    redirect: editDqAsoZulI8Meta?.redirect || undefined,
    component: () => import("/vercel/path0/web/pages/courses/[courseId]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexos0vIsGzhbMeta?.name ?? "courses-courseId",
    path: indexos0vIsGzhbMeta?.path ?? "/courses/:courseId()",
    meta: indexos0vIsGzhbMeta || {},
    alias: indexos0vIsGzhbMeta?.alias || [],
    redirect: indexos0vIsGzhbMeta?.redirect || undefined,
    component: () => import("/vercel/path0/web/pages/courses/[courseId]/index.vue").then(m => m.default || m)
  },
  {
    name: LoggedInIndexJYMIzElVFVMeta?.name ?? "courses-courseId-LoggedInIndex",
    path: LoggedInIndexJYMIzElVFVMeta?.path ?? "/courses/:courseId()/LoggedInIndex",
    meta: LoggedInIndexJYMIzElVFVMeta || {},
    alias: LoggedInIndexJYMIzElVFVMeta?.alias || [],
    redirect: LoggedInIndexJYMIzElVFVMeta?.redirect || undefined,
    component: () => import("/vercel/path0/web/pages/courses/[courseId]/LoggedInIndex.vue").then(m => m.default || m)
  },
  {
    name: LoggedOutIndex2lHB6vVosjMeta?.name ?? "courses-courseId-LoggedOutIndex",
    path: LoggedOutIndex2lHB6vVosjMeta?.path ?? "/courses/:courseId()/LoggedOutIndex",
    meta: LoggedOutIndex2lHB6vVosjMeta || {},
    alias: LoggedOutIndex2lHB6vVosjMeta?.alias || [],
    redirect: LoggedOutIndex2lHB6vVosjMeta?.redirect || undefined,
    component: () => import("/vercel/path0/web/pages/courses/[courseId]/LoggedOutIndex.vue").then(m => m.default || m)
  },
  {
    name: previewPCevnetqdxMeta?.name ?? "courses-courseId-preview",
    path: previewPCevnetqdxMeta?.path ?? "/courses/:courseId()/preview",
    meta: previewPCevnetqdxMeta || {},
    alias: previewPCevnetqdxMeta?.alias || [],
    redirect: previewPCevnetqdxMeta?.redirect || undefined,
    component: () => import("/vercel/path0/web/pages/courses/[courseId]/preview.vue").then(m => m.default || m)
  },
  {
    name: runDT6Ya0SZzZMeta?.name ?? "courses-courseId-run",
    path: runDT6Ya0SZzZMeta?.path ?? "/courses/:courseId()/run",
    meta: runDT6Ya0SZzZMeta || {},
    alias: runDT6Ya0SZzZMeta?.alias || [],
    redirect: runDT6Ya0SZzZMeta?.redirect || undefined,
    component: () => import("/vercel/path0/web/pages/courses/[courseId]/run.vue").then(m => m.default || m)
  },
  {
    name: create0OIsbOkZu5Meta?.name ?? "courses-create",
    path: create0OIsbOkZu5Meta?.path ?? "/courses/create",
    meta: create0OIsbOkZu5Meta || {},
    alias: create0OIsbOkZu5Meta?.alias || [],
    redirect: create0OIsbOkZu5Meta?.redirect || undefined,
    component: () => import("/vercel/path0/web/pages/courses/create.vue").then(m => m.default || m)
  },
  {
    name: debug_45mapsid7aRh3Ym5Meta?.name ?? "debug-maps",
    path: debug_45mapsid7aRh3Ym5Meta?.path ?? "/debug-maps",
    meta: debug_45mapsid7aRh3Ym5Meta || {},
    alias: debug_45mapsid7aRh3Ym5Meta?.alias || [],
    redirect: debug_45mapsid7aRh3Ym5Meta?.redirect || undefined,
    component: () => import("/vercel/path0/web/pages/debug-maps.vue").then(m => m.default || m)
  },
  {
    name: coursesdnOySbgHo9Meta?.name ?? "explore-courses",
    path: coursesdnOySbgHo9Meta?.path ?? "/explore/courses",
    meta: coursesdnOySbgHo9Meta || {},
    alias: coursesdnOySbgHo9Meta?.alias || [],
    redirect: coursesdnOySbgHo9Meta?.redirect || undefined,
    component: () => import("/vercel/path0/web/pages/explore/courses.vue").then(m => m.default || m)
  },
  {
    name: indexEnecjc5U7LMeta?.name ?? "explore",
    path: indexEnecjc5U7LMeta?.path ?? "/explore",
    meta: indexEnecjc5U7LMeta || {},
    alias: indexEnecjc5U7LMeta?.alias || [],
    redirect: indexEnecjc5U7LMeta?.redirect || undefined,
    component: () => import("/vercel/path0/web/pages/explore/index.vue").then(m => m.default || m)
  },
  {
    name: indexjqPwd0e7eHMeta?.name ?? "index",
    path: indexjqPwd0e7eHMeta?.path ?? "/",
    meta: indexjqPwd0e7eHMeta || {},
    alias: indexjqPwd0e7eHMeta?.alias || [],
    redirect: indexjqPwd0e7eHMeta?.redirect || undefined,
    component: () => import("/vercel/path0/web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginzz3zAXJZnbMeta?.name ?? "login",
    path: loginzz3zAXJZnbMeta?.path ?? "/login",
    meta: loginzz3zAXJZnbMeta || {},
    alias: loginzz3zAXJZnbMeta?.alias || [],
    redirect: loginzz3zAXJZnbMeta?.redirect || undefined,
    component: () => import("/vercel/path0/web/pages/login.vue").then(m => m.default || m)
  },
  {
    name: privacyTancXnnePpMeta?.name ?? "privacy",
    path: privacyTancXnnePpMeta?.path ?? "/privacy",
    meta: privacyTancXnnePpMeta || {},
    alias: privacyTancXnnePpMeta?.alias || [],
    redirect: privacyTancXnnePpMeta?.redirect || undefined,
    component: () => import("/vercel/path0/web/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: checkout7mNSwKKE0oMeta?.name ?? "pro-checkout",
    path: checkout7mNSwKKE0oMeta?.path ?? "/pro/checkout",
    meta: checkout7mNSwKKE0oMeta || {},
    alias: checkout7mNSwKKE0oMeta?.alias || [],
    redirect: checkout7mNSwKKE0oMeta?.redirect || undefined,
    component: () => import("/vercel/path0/web/pages/pro/checkout.vue").then(m => m.default || m)
  },
  {
    name: analyzeudDP1RE8keMeta?.name ?? "runs-runId-analyze",
    path: analyzeudDP1RE8keMeta?.path ?? "/runs/:runId()/analyze",
    meta: analyzeudDP1RE8keMeta || {},
    alias: analyzeudDP1RE8keMeta?.alias || [],
    redirect: analyzeudDP1RE8keMeta?.redirect || undefined,
    component: () => import("/vercel/path0/web/pages/runs/[runId]/analyze.vue").then(m => m.default || m)
  },
  {
    name: indexhdf1FNgDNfMeta?.name ?? "runs-runId",
    path: indexhdf1FNgDNfMeta?.path ?? "/runs/:runId()",
    meta: indexhdf1FNgDNfMeta || {},
    alias: indexhdf1FNgDNfMeta?.alias || [],
    redirect: indexhdf1FNgDNfMeta?.redirect || undefined,
    component: () => import("/vercel/path0/web/pages/runs/[runId]/index.vue").then(m => m.default || m)
  },
  {
    name: supportLG7pJ0waS8Meta?.name ?? "support",
    path: supportLG7pJ0waS8Meta?.path ?? "/support",
    meta: supportLG7pJ0waS8Meta || {},
    alias: supportLG7pJ0waS8Meta?.alias || [],
    redirect: supportLG7pJ0waS8Meta?.redirect || undefined,
    component: () => import("/vercel/path0/web/pages/support.vue").then(m => m.default || m)
  },
  {
    name: termsXy0y1ahYNqMeta?.name ?? "terms",
    path: termsXy0y1ahYNqMeta?.path ?? "/terms",
    meta: termsXy0y1ahYNqMeta || {},
    alias: termsXy0y1ahYNqMeta?.alias || [],
    redirect: termsXy0y1ahYNqMeta?.redirect || undefined,
    component: () => import("/vercel/path0/web/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: coursesDpPbHusfM6Meta?.name ?? "users-userId-courses",
    path: coursesDpPbHusfM6Meta?.path ?? "/users/:userId()/courses",
    meta: coursesDpPbHusfM6Meta || {},
    alias: coursesDpPbHusfM6Meta?.alias || [],
    redirect: coursesDpPbHusfM6Meta?.redirect || undefined,
    component: () => import("/vercel/path0/web/pages/users/[userId]/courses.vue").then(m => m.default || m)
  },
  {
    name: indexAaeAPqJ4dsMeta?.name ?? "users-userId",
    path: indexAaeAPqJ4dsMeta?.path ?? "/users/:userId()",
    meta: indexAaeAPqJ4dsMeta || {},
    alias: indexAaeAPqJ4dsMeta?.alias || [],
    redirect: indexAaeAPqJ4dsMeta?.redirect || undefined,
    component: () => import("/vercel/path0/web/pages/users/[userId]/index.vue").then(m => m.default || m)
  }
]