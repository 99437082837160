<template>
  <div class="logged-out">
    <header>
      <nav>
        <nuxt-link to="/">
          <h1>orienteer</h1>
        </nuxt-link>
      </nav>
    </header>
    <slot />
  </div>
</template>
<style scoped>
.logged-out {
  width: 100%;
  min-height: 100vh;
  display: grid;

  align-content: center;
  justify-content: center;
  background: url("/hero-1.jpeg") no-repeat;
  background-position: center;
  background-size: cover;
}

.logged-out header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: calc(var(--grid) * 3);
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
}

header h1 {
  color: var(--brand);
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: -2px;
  line-height: calc(var(--grid) * 3);
}

.logged-out > :deep(:not(header)) {
  margin-top: var(--grid-4);

  background-color: var(--white);
  padding: var(--grid);
  border-radius: var(--quarter-grid);
  box-shadow: var(--prominent-shadow);
  width: calc(16 * var(--grid));

  display: flex;
  flex-direction: column;
  justify-items: stretch;
}

@media (max-width: 700px) {
  .logged-out {
    justify-content: center;
  }
  .logged-out > div {
    margin-right: auto;
    margin-left: auto;
  }
}
</style>
