export function param<T>(raw: T | T[]): T {
  const p = Array.isArray(raw) ? raw[0] : raw;
  if (typeof p === "string") return p.trim() as T;
  return p;
}

export function useParam(name: string): Ref<string> {
  const route = useRoute();
  return computed(() => param(route.params[name]));
}

export function useQueryParam(name: string): Ref<string> {
  const route = useRoute();
  return computed(() => param(route.query[name]));
}
