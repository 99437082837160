type SentryImportType = typeof import("@sentry/vue");

const isDev = process.env.NODE_ENV !== "production";

const oldOnError = window.onerror;
const oldOnUnhandledRejection = window.onunhandledrejection;
window.onerror = (...args) => errorQueue.push(args);
window.onunhandledrejection = (e: PromiseRejectionEvent) =>
  rejectionQueue.push(e);

let queue: Array<(sentry: SentryImportType) => void> = [];
let errorQueue: Array<Parameters<OnErrorEventHandlerNonNull>> = [];
let rejectionQueue: Array<PromiseRejectionEvent> = [];

// Before Sentry has loaded, these functions will push calls into a queue
// After Sentry has loaded, these will be replaced with the real functions
export let addBreadcrumb: SentryImportType["addBreadcrumb"] = (...args) => {
  queue.push((x) => x.addBreadcrumb(...args));
};
export let captureMessage: SentryImportType["captureMessage"] = (...args) => {
  queue.push((x) => x.captureMessage(...args));
  return "";
};
export let captureException: SentryImportType["captureException"] = (
  ...args
) => {
  queue.push((x) => x.captureException(...args));
  return "";
};
export let captureEvent: SentryImportType["captureEvent"] = (...args) => {
  queue.push((x) => x.captureEvent(...args));
  return "";
};
export let setUser: SentryImportType["setUser"] = (...args) => {
  queue.push((x) => x.setUser(...args));
  return "";
};
export let configureScope: SentryImportType["configureScope"] = (...args) =>
  queue.push((x) => x.configureScope(...args));

export let withScope: SentryImportType["withScope"] = (...args) => {
  // This has type issues:
  // queue.push(x => x.withScope(...args));
  throw new Error("Early withScope not implemented");
};

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();
  const {
    public: { sentry },
  } = useRuntimeConfig() as any;

  const SentryStandin = {
    addBreadcrumb,
    captureMessage,
    captureException,
    captureEvent,
    configureScope,
    setUser,
    withScope,
  };

  setTimeout(() => {
    if (localStorage && localStorage.getItem("is-playwright") === "true")
      return;

    import("@sentry/vue").then((Sentry) => {
      window.onerror = oldOnError;
      window.onunhandledrejection = oldOnUnhandledRejection;
      let environment = isDev ? "development" : "production";
      try {
        if (localStorage && localStorage.getItem("is-playwright") === "true") {
          environment = "playwright";
        }
      } catch (x) {
        captureException(x);
      }

      Sentry.init({
        dsn: sentry.dsn,
        app: nuxtApp.vueApp,
        debug: isDev,
        environment,
        integrations: [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          }),
          new Sentry.Replay({
            maskAllText: false,
          }),
        ],
        tracesSampleRate: 0.01, // Change in prod

        tracePropagationTargets: [
          "https://orienteer.co/",
          "https://nmywglyfswckroxbrjqz.supabase.co",
          "https://api.orienteer.co",
        ],

        replaysSessionSampleRate: 0.01,
        replaysOnErrorSampleRate: 1.0,
      });

      SentryStandin.addBreadcrumb = Sentry.addBreadcrumb.bind(Sentry);
      SentryStandin.captureMessage = Sentry.captureMessage.bind(Sentry);
      SentryStandin.captureException = Sentry.captureException.bind(Sentry);
      SentryStandin.captureEvent = Sentry.captureEvent.bind(Sentry);
      SentryStandin.configureScope = Sentry.configureScope.bind(Sentry);
      SentryStandin.setUser = Sentry.setUser.bind(Sentry);
      SentryStandin.withScope = Sentry.withScope.bind(Sentry);

      queue.forEach((call) => call(Sentry));
      errorQueue.forEach((x) => window.onerror?.(...x));
      rejectionQueue.forEach((e) => window.onunhandledrejection?.(e));
    });
  }, 500);

  return {
    provide: {
      sentry: SentryStandin,
    },
  };
});
