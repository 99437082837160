<template>
  <div v-if="authError">
    <LoggedOutBlocker>
      <div class="error">
        <h3>Authentication Error ({{ authErrorCode }})</h3>
        {{ authErrorDescription }} ({{ authError.split("_").join(" ") }})
        <NuxtLink class="button" :to="{ query: {} }">OK</NuxtLink>
      </div>
    </LoggedOutBlocker>
  </div>
  <div v-else-if="user || route.meta.authenticated === false">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
  <div v-else>
    <LoggedOutBlocker>
      <AuthenticateForm />
    </LoggedOutBlocker>
  </div>
</template>
<script setup lang="ts">
import "./global.css";
import "@formkit/themes/genesis";

const route = useRoute();
const user = useUser();

const sentry = useSentry();

watch(
  [user],
  () => {
    sentry?.setUser({ id: user.value?.id, email: user.value?.email });
  },
  { immediate: true }
);

const authError = useQueryParam("error");
const authErrorCode = useQueryParam("error_code");
const authErrorDescription = useQueryParam("error_description");
</script>
<style scoped>
.error {
  padding: var(--grid);
  display: flex;
  flex-direction: column;
  gap: var(--half-grid);
}
</style>
