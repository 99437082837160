<template>
  <button
    :disabled="active"
    :class="{ button: true, active, primary, link }"
    @click="click"
  >
    <slot />
  </button>
</template>
<script lang="ts" setup>
const props = defineProps({
  primary: Boolean,
  link: Boolean,
  href: String,
  click: Function,
});

const router = useRouter();
const emit = defineEmits(["click"]);
const component = getCurrentInstance();
const active = ref(false);

async function click(e: MouseEvent) {
  if (props.href) {
    router.push(props.href);
  } else {
    emit("click", e);

    if (props.click) {
      try {
        active.value = true;
        await props.click(e);
      } catch (x) {
        alert("Error: " + JSON.stringify(x));
      } finally {
        active.value = false;
      }
    }
  }
}
</script>
<style scoped>
.link {
  background-color: transparent;
  outline: none;
  border: none;
  padding: var(--quarter-grid) 0px;
  color: black;
  text-transform: unset;
}
.link:hover {
  background-color: unset;
  text-decoration: underline;
}
</style>
