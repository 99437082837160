<template>
  <div v-if="mode == 'register'" class="authenticate-form">
    <h3>Join Orienteer today, it's free.</h3>
    <FormKit type="form" @submit="register" :errors="error ? [error] : []">
      <FormKit type="email" name="email" label="Email" validation="required" />
      <FormKit
        type="password"
        name="password"
        label="Password"
        validation="required|length:8"
      />
    </FormKit>

    <Button @click="mode = 'login'" link>Log in instead</Button>
  </div>

  <div v-else-if="mode == 'forgot'" class="authenticate-form">
    <h3>Forgot password?</h3>
    <FormKit type="form" @submit="forgot" :errors="error ? [error] : []">
      <FormKit type="email" name="email" label="Email" />
    </FormKit>

    <Button @click="mode = 'login'" link>Back to login</Button>
  </div>
  <div v-else-if="mode == 'forgot-success'" class="authenticate-form">
    <h3>Forgot password?</h3>

    <p>Check your email for a recovery link!</p>
  </div>
  <div v-else-if="mode == 'login'" class="authenticate-form">
    <h3>Login</h3>
    <FormKit type="form" @submit="login" :errors="error ? [error] : []">
      <FormKit type="text" inputmode="email" name="email" label="Email" />
      <FormKit type="password" name="password" label="Password" />
    </FormKit>

    <Button @click="oauth('google')" class="oauth google">
      <GoogleIcon />
      <span>Sign in with Google</span>
    </Button>
    <Button @click="oauth('apple')" class="oauth apple">
      <AppleIcon />
      <span>Sign in with Apple</span>
    </Button>

    <Button @click="mode = 'register'" link>Register instead</Button>
    <Button @click="mode = 'forgot'" link>Forgot password</Button>

    <ul>
      <li>
        <NuxtLink to="/privacy">Privacy Policy</NuxtLink>
      </li>
      <li>
        <NuxtLink to="/terms">Terms of Service</NuxtLink>
      </li>
    </ul>
  </div>
</template>
<script setup lang="ts">
const props = defineProps<{ mode?: "login" | "register" }>();

const mode = ref<
  "login" | "forgot" | "forgot-success" | "register" | "reset-success"
>(props.mode ? props.mode : "login");
const error = ref();
const router = useRouter();
const route = useRoute();
const client = useClient();

async function login(form: { email: string; password: string }) {
  try {
    const { data, error: signInError } = await client.auth.signInWithPassword(
      form
    );
    if (signInError) {
      error.value = signInError.message;
    }
  } catch (x) {
    error.value = JSON.stringify(x);
  }
}
watch(mode, () => (error.value = null));
async function register(form: RegisterInput) {
  try {
    const { error: signUpError, data } = await client.auth.signUp(form);
    if (signUpError) {
      error.value = signUpError.message;
      return;
    }

    // gotta refresh in order to get the public_user_id in the token:
    await client.auth.refreshSession();
  } catch (x) {
    error.value = JSON.stringify(x);
  }
}

async function forgot({ email }: { email: string }) {
  const { error: forgotError } = await client.auth.resetPasswordForEmail(
    email,
    {
      redirectTo: `${document.location.protocol}//${
        document.location.hostname
      }${
        document.location.port ? ":" + document.location.port : ""
      }/account/reset-password`,
    }
  );

  if (forgotError) {
    error.value = forgotError.message;
    return;
  }

  mode.value = "forgot-success";
}

function returnToLogin() {
  mode.value = "login";
  router.push({ query: {}, path: route.path });
}

function oauth(partner: "google" | "apple") {
  return client.auth.signInWithOAuth({
    provider: partner,
    options: { redirectTo: window.document.location.href },
  });
}
</script>
<style scoped>
.authenticate-form {
  min-width: calc(min(16 * var(--grid), 90vw));
}

@media screen and (max-width: 450px) {
  .authenticate-form {
    min-width: calc(min(8 * var(--grid), 95vw));
  }
}

:deep(form button),
:deep(form input),
:deep(form [data-type="submit"] .formkit-input) {
  width: 100%;
}

.oauth {
  min-height: 3rem;
  width: 100%;

  display: grid;
  grid-template-columns: auto 1fr;

  align-items: center;
  justify-items: center;

  border-radius: 5px;
  margin-top: 1em;
  padding: 0px;
}

.button.link {
  width: 100%;
}

.oauth > svg {
  height: 100%;
  width: 100%;
}

.oauth.google {
  background-color: #4285f4;
  color: white;
}

.oauth.apple {
  background-color: black;
  color: white;
}
</style>
